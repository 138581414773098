//! server-and-client

import type { JsonValue } from 'type-fest';
import { memoize, once } from 'lodash';

/**
 * @note we don't use the `ServerLogger` here because this context is used on Client Components also.
 * @todo use Sentry logger
 */
const logErrorOnce = memoize((...args: unknown[]) => once(() => console.error(...args))());

type JsonValueRequestScoped = () => JsonValue;

type GlobalContextValue = JsonValue | JsonValueRequestScoped;

class GlobalContext extends Map<string, GlobalContextValue> {
  /**
   * All values have to be set explicitly to avoid confusing not set values with flags set to false.
   */
  get<T extends GlobalContextValue = JsonValue>(key: string): T {
    const val = super.get(key);
    if (typeof val === 'undefined') {
      const errMsg = `GlobalContext: key '${key}' not found`;
      if (process.env.NODE_ENV !== 'production') {
        // crash at development time to raise awareness of missing values
        throw new TypeError(errMsg);
      }
      logErrorOnce(errMsg);
    }
    return val as T;
  }
}

/**
 * Global singleton used to set data to be used by both the **browser and server**.
 *
 * This is an alternative to React Context for when we want to read context data outside of React components.
 * eg. a client util function that needs to read a config value.
 *
 * @important To set values that are available on the server and the client use the `<SetGlobalContextValue />` component.
 *
 * @warn Normal keys are global and will maintain state between requests on server.
 *
 * If you need a value to be request scoped, tag the key as function like `key()`.\
 * eg. `{ 'requestId()': 1234 }`
 *
 * To get a request scoped value you'll have to call the function:\
 * eg. `requestId = context.get('requestId()')()`
 *
 * @note This is sent over the network so make sure to set values only on the pages that use them.
 * Otherwise the payload we send to the client will increase with unused data and degrade performance.
 *
 */
export const context = new GlobalContext();
