//! server-and-client

import { context } from '@/contexts/GlobalContext';
import type { StageEnv } from '@/types';

export const IS_LOCAL_RUNTIME = process.env.NEXT_PUBLIC_LOCAL === 'true';

/**
 * @note Don't use this for render conditions in Client Components
 * because it will cause a mismatch between server and client rendering.
 * Instead use `useRenderAfterHydration()`.
 *
 * @note doesn't make sense to use it on Server Components because it's always `false`.
 */
export const isBrowser = typeof window !== 'undefined';

export const getEnvStage = (): StageEnv => context.get<StageEnv>('stageEnv');

export const isEnvStage = (stage: StageEnv): boolean => getEnvStage() === stage;
