'use client';

import type { JsonObject } from 'type-fest';
import { context } from '../context';

export interface SetGlobalContextValueProps {
  data: JsonObject;
}

export function SetGlobalContextValueClient({ data }: SetGlobalContextValueProps): null {
  for (const [key, value] of Object.entries(data)) {
    context.set(key, key.endsWith('()') ? () => value : value);
  }

  return null;
}
