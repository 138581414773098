import { cache } from 'react';
import { context } from '../context';
import type { SetGlobalContextValueProps } from './SetGlobalContextValueClient';
import { SetGlobalContextValueClient } from './SetGlobalContextValueClient';

/**
 * Set the same value on **browser and server**.
 *
 * @warn for server request scoped values use `key()`.
 *
 * @note Because of how rendering executes (all Server Components first and then all Client Components)
 * we have to do the following for the data to be available on the server and the client.
 * - set data in a Server Component
 * - pass data to a Client Component (serializable over network)
 * - set data in the Client Component
 *
 * @link https://nextjs.org/docs/app/building-your-application/rendering/composition-patterns#interleaving-server-and-client-components
 */
export function SetGlobalContextValue({ data }: SetGlobalContextValueProps): JSX.Element {
  for (const [key, value] of Object.entries(data)) {
    context.set(key, key.endsWith('()') ? cache(() => value) : value);
  }

  return <SetGlobalContextValueClient data={data} />;
}
